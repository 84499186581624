//
// Check/radio
//

// makes the default checkbox icon nicer
.form-check-input {
  border-color: rgba(0, 0, 0, .25);
  box-shadow: 0 0 0 1px rgba(111, 111, 111, 0.4);
}

// Hides the default caret
.form-check-input::-ms-check {
  color: transparent;
  background-color: transparent;
  border: 0;
}

// Unstyle the caret on `<select>`s
select::-ms-expand {
  display: none;
}

.form-check .form-check-input[type="radio"] {
  margin-top: 0.27em;
  margin-left: -1.3em;
}

//
// Switch
//

.form-switch .form-check-input,
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -4 8 8'%3e%3ccircle r='3' fill='%23ccc'/%3e%3c/svg%3e");
}

.form-switch :checked.form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-8 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
